import { Provider } from 'wikr-core-analytics';
import { SENTRY_ANALYTIC, ERROR_LEVELS } from 'sentry-utils';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import { selectCurrentUser } from 'store/user/selectors';

import sentry from 'services/Sentry/SentryInstance';

import { useSelector } from 'hooks';

import { trackScreenLoad } from './trackers/mainTrackers';

export function useAnalytic() {
    const location = useLocation();
    const userData = useSelector(selectCurrentUser);

    const { country, user_id } = userData;

    const getUtmSource = (): string | undefined => localStorage.getItem('utmSource') || undefined;

    const getCountry = async (): Promise<string> => {
        return new Promise((resolve) => {
            resolve(country as string);
        });
    };

    const getAbTestName = (): Promise<string | null> => {
        const abTestName = null;

        return new Promise((resolve) => {
            resolve(abTestName);
        });
    };

    // TODO: refactor after changes in Provider
    const setAnalyticData = () => {
        Provider.provideData({
            country: async () => await getCountry(),
            abTestName: async () => await getAbTestName(),
            utmSource: getUtmSource(),
            clientHandlerFn: (error: unknown | Error) =>
                sentry.logError(error, SENTRY_ANALYTIC, ERROR_LEVELS.ERROR, {}),
        });
        user_id && Provider.setUserId(user_id);
    };

    useEffect(() => {
        if (user_id) {
            setAnalyticData();
        }
    }, [user_id]);

    useEffect(() => {
        if (userData) {
            if (
                location?.pathname === '/' ||
                // We have separate handler there
                (location?.pathname ?? '').startsWith('/account/subscription')
            )
                return;

            trackScreenLoad();
        }
    }, [location?.pathname]);
}
