import { Box } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import { selectSubscriptions } from 'store/subscriptions/selectors';
import { setSubscriptionCancelReminder } from 'store/subscriptions/actions';
import { openModal } from 'store/modals/actions';

import {
    OfferTypes,
    cancelFlow_cancelOfferClick,
    cancelFlow_cancelOfferScreenLoad,
    cancelFlow_offerResultClick,
} from 'services/analytics/trackers/cancelFlow';

import { useCancelSubscription, useDispatch, useModals, useSelector } from 'hooks';

import { reminderReasons } from 'containers/modals/ReminderReasonModalContainer/constatnts';
import ReminderReason from 'containers/modals/ReminderReasonModalContainer/components/ReminderReason/ReminderReason';
import ReasonCard from 'containers/modals/ReminderReasonModalContainer/components/ReasonCard';

import OutlinedButton from 'components/OutlinedButton';
import ModalHeader from 'components/ModalHeader';
import Modal from 'components/Modal';
import { Button, Text } from 'components/library';

import * as S from './styled';

import { ModalName } from 'types/modals';

const ReminderReasonModalContainer = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { closeModal } = useModals();

    const { discardCancellation, onCancelSubscription } = useCancelSubscription();

    const { cancellationCandidate } = useSelector(selectSubscriptions);

    useEffect(() => {
        if (cancellationCandidate) {
            cancelFlow_cancelOfferScreenLoad({
                offer_type: OfferTypes.Reminder_offer,
                subscription_id: cancellationCandidate.external_id,
            });
        }
    }, [cancellationCandidate]);

    const setReminder = () => {
        dispatch(openModal(ModalName.WAITING_MODAL));

        if (cancellationCandidate) {
            cancelFlow_cancelOfferClick({
                action: 'claim_offer',
                subscription_id: cancellationCandidate.external_id,
                offer_type: OfferTypes.Reminder_offer,
            });

            dispatch(
                setSubscriptionCancelReminder({
                    externalId: cancellationCandidate.external_id,
                    paymentProvider: cancellationCandidate.payment_provider,
                    onError: () => {
                        dispatch(
                            openModal(ModalName.ERROR_MODAL, {
                                tryAgain: () => {
                                    cancelFlow_offerResultClick({
                                        action: 'try_again',
                                        result: 'fail',
                                        offer_type: OfferTypes.Reminder_offer,
                                        subscription_id: cancellationCandidate.external_id,
                                    });
                                    setReminder();
                                },
                            })
                        );
                    },
                    onSuccess: () => {
                        dispatch(openModal(ModalName.REMINDER_HAS_BEEN_SET_MODAL));
                    },
                    onCancel: () => null,
                })
            );
        }
    };

    const handleClose = () => {
        closeModal();
        discardCancellation();

        if (cancellationCandidate) {
            cancelFlow_cancelOfferClick({
                action: 'close',
                subscription_id: cancellationCandidate.external_id,
                offer_type: OfferTypes.Reminder_offer,
            });
        }
    };

    const goBack = () => {
        dispatch(openModal(ModalName.REASON_MODAL));
    };

    const handleCancel = () => {
        onCancelSubscription();

        if (cancellationCandidate) {
            cancelFlow_cancelOfferClick({
                action: 'cancel',
                subscription_id: cancellationCandidate.external_id,
                offer_type: OfferTypes.Reminder_offer,
            });
        }
    };

    return (
        <Modal isOpen fullscreen customStyles={{ content: { overflow: 'scroll' } }}>
            <ModalHeader onBack={goBack} onClose={handleClose} />
            <S.Container>
                <Box paddingTop={20} paddingBottom={100} paddingX={16}>
                    <Text type="h4" center mb={24} text={t('subscription.reminderModal.title')} />
                    <Text type="large-text" center mb={24} text={t('subscription.reminderModal.subTitle')} />

                    {reminderReasons.map((reason) => (
                        <ReminderReason badge={reason.badge} title={reason.title} key={reason.title}>
                            {reason.children.map((reasonSubList) => {
                                if (reasonSubList.type === 'card') {
                                    return (
                                        <ReasonCard
                                            key={reasonSubList.title}
                                            title={reasonSubList.title || ''}
                                            badge={reasonSubList.badge || ''}
                                            bodyText={reasonSubList.bodyText}
                                        />
                                    );
                                } else {
                                    return (
                                        <Text
                                            type="small-text"
                                            text={t(reasonSubList.bodyText)}
                                            key={reasonSubList.bodyText}
                                        />
                                    );
                                }
                            })}
                        </ReminderReason>
                    ))}
                    <Button
                        mb={20}
                        onClick={setReminder}
                        text={t('subscription.reminderModal.button.setReminder')}
                        dataLocator="obContinue"
                    />
                    <OutlinedButton
                        text={t('subscription.text.cancel')}
                        onClick={handleCancel}
                        dataLocator="keepSubBtn"
                    />
                </Box>
            </S.Container>
        </Modal>
    );
};

export default ReminderReasonModalContainer;
