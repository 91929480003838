import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { sendAnalyticResetPassContactUsClick } from 'services/analytics';

import { useGetCFLink } from 'hooks';

import { Colors, Text } from 'components/library';

import { Link } from './styled';

import { color } from 'theme/selectors';

export const ContactButton = styled.a`
    display: block;
    padding: 19px;
    color: ${color('text-main')};
    font-size: 17px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    background: ${color('surface-main')};
    border: 1px solid ${color('border-default')};
    border-radius: 12px;
`;

type ContactUsLinkProps = {
    variant?: 'button' | 'default';
    color?: Colors;
    text?: string;
    onSupportClick?: () => void;
};

const ContactUsLink = ({ color, text, variant, onSupportClick }: ContactUsLinkProps) => {
    const { t } = useTranslation();
    const link = useGetCFLink();

    if (variant === 'button') {
        return (
            <ContactButton
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                data-locator="contactUsLink"
                onClick={onSupportClick ? onSupportClick : sendAnalyticResetPassContactUsClick}
            >
                <Text type="overline" color={color} center bold text={text ?? t('basics.contactUs')} />
            </ContactButton>
        );
    }

    return (
        <Link
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            data-locator="contactUsLink"
            onClick={sendAnalyticResetPassContactUsClick}
        >
            <Text type="overline" color={color} center bold text={text ?? t('basics.contactUs')} />
        </Link>
    );
};

export default ContactUsLink;
