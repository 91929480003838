import { Box, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import { cancelFlow_cancelResultClick, cancelFlow_offerResultClick } from 'services/analytics/trackers/cancelFlow';

import { useModals } from 'hooks';

import Modal from 'components/Modal';
import { Button } from 'components/library';
import ContactUsLink from 'components/ContactUsLink';

import { WarningModalContainerProps } from './types';

const ErrorFeedbackModalContainer = ({
    title,
    offerType,
    onMainBtnClick,
    analyticScreenLoadHandler,
    subscription_id,
    onSupportClick,
}: WarningModalContainerProps) => {
    const { t } = useTranslation();
    const { closeModal } = useModals();

    useEffect(() => {
        analyticScreenLoadHandler && analyticScreenLoadHandler();
    }, []);

    const handleClose = () => {
        if (offerType) {
            cancelFlow_offerResultClick({
                action: 'close',
                result: 'fail',
                subscription_id,
                offer_type: offerType,
            });
        }

        if (!offerType && subscription_id) {
            cancelFlow_cancelResultClick({ action: 'close', result: 'fail', subscription_id });
        }

        closeModal();
    };

    return (
        <Modal isOpen withCloseButton onClose={handleClose}>
            <Box paddingTop={60} paddingBottom={30} paddingX={16}>
                <Text type="h1" center text="❗" mb={20} />
                <Text type="h5" text={title} mb={24} center />
                <Button text={t('basics.tryAgain')} onClick={onMainBtnClick} mb={24} />
                <ContactUsLink onSupportClick={onSupportClick} text={t('basics.contactSupport')} />
            </Box>
        </Modal>
    );
};

export default ErrorFeedbackModalContainer;
