import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { selectSubscriptions } from 'store/subscriptions/selectors';
import { cancelSubscription, fetchDiscountSubscriptions, setCancellationCandidate } from 'store/subscriptions/actions';

import { DISCOUNT_TYPES } from 'constants/subscriptions';

import {
    OfferTypes,
    cancelFlow_cancelResultScreenLoad,
    cancelFlow_offerResultScreenLoad,
    cancelFlow_cancelResultClick,
} from 'services/analytics/trackers/cancelFlow';

import { useDispatch, useSelector, useModals } from 'hooks';

import { ISubscription, PaymentProviders } from 'types/subscription';
import { ModalName } from 'types/modals';

const useCancelSubscription = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { openModal, closeModal } = useModals();
    const { cancellationCandidate } = useSelector(selectSubscriptions);

    const isFeeEnabled = cancellationCandidate?.fee?.fee_enabled;

    const hasDiscount = Boolean(cancellationCandidate?.discounts.discount);

    const discardCancellation = useCallback(() => {
        dispatch(setCancellationCandidate(null));
    }, []);

    const handleSubscriptionCanceledPopupClosed = (candidate: ISubscription | null) => {
        if (
            !hasDiscount &&
            !candidate?.fee?.fee_enabled &&
            candidate?.payment_provider === PaymentProviders.Solidgate
        ) {
            dispatch(
                fetchDiscountSubscriptions({
                    discount_type: DISCOUNT_TYPES.extra,
                    external_id: candidate!.external_id,
                    onSuccess() {
                        openModal(ModalName.RETURN_OFFER);
                    },
                    onError() {
                        closeModal();
                    },
                })
            );
        } else closeModal();
    };

    const onCancelSubscription = useCallback(
        (e?: unknown, candidate: ISubscription | null = cancellationCandidate) => {
            candidate = candidate ?? cancellationCandidate;

            openModal(ModalName.WAITING_MODAL, { title: 'subscription.cancelDialog.waiting' });

            if (candidate) {
                dispatch(
                    cancelSubscription({
                        externalId: candidate.external_id,
                        paymentProvider: candidate.payment_provider,
                        onError: () => {
                            discardCancellation();

                            openModal(ModalName.WARNING_MODAL, {
                                onMainBtnClick: () => {
                                    cancelFlow_cancelResultClick({
                                        action: 'try_again',
                                        result: 'fail',
                                        subscription_id: candidate?.external_id,
                                    });
                                    onCancelSubscription();
                                },
                                onSupportClick: () =>
                                    cancelFlow_cancelResultClick({
                                        action: 'contact_support',
                                        result: 'fail',
                                        subscription_id: candidate?.external_id,
                                    }),
                                title: t('subscription.cancelFlow.cancellationError.title'),
                                subscription_id: candidate?.external_id,
                                analyticScreenLoadHandler() {
                                    if (!candidate) return;

                                    if (isFeeEnabled) {
                                        cancelFlow_offerResultScreenLoad({
                                            result: 'fail',
                                            subscription_id: candidate.external_id,
                                            offer_type: OfferTypes.Fee_offer,
                                            fee_ammount: candidate.fee.fee_price,
                                            fee_result: 'fail',
                                        });
                                    }

                                    cancelFlow_cancelResultScreenLoad({
                                        result: 'fail',
                                        subscription_id: candidate.external_id,
                                    });
                                },
                            });
                        },
                        onSuccess: () => {
                            if (candidate) {
                                cancelFlow_cancelResultScreenLoad({
                                    result: 'success',
                                    subscription_id: candidate.external_id,
                                });

                                if (isFeeEnabled) {
                                    cancelFlow_offerResultScreenLoad({
                                        result: 'success',
                                        subscription_id: candidate.external_id,
                                        offer_type: OfferTypes.Fee_offer,
                                        fee_ammount: candidate.fee.fee_price,
                                        fee_result: 'success',
                                    });
                                }
                            }

                            openModal(ModalName.SUCCESS_MODAL, {
                                title: t('subscription.cancellation.response.success'),
                                subtitle: t('subscription.cancellation.response.success.subtitle'),
                                onClose: () => handleSubscriptionCanceledPopupClosed(candidate),
                                externalId: candidate?.external_id,
                            });

                            discardCancellation();
                        },
                        onCancel: discardCancellation,
                    })
                );
            }
        },
        [cancellationCandidate]
    );

    return { discardCancellation, onCancelSubscription, cancellationCandidate };
};

export default useCancelSubscription;
