import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectCountry } from 'store/user/selectors';

import {
    PRODUCT_CODES,
    SUBSCRIPTION_ACTIVE,
    SUBSCRIPTION_CANCELLED,
    SUBSCRIPTION_CHARGEBACK,
    SUBSCRIPTION_PAUSED,
    SUBSCRIPTION_PENDING,
    SUBSCRIPTION_REDEMPTION,
} from 'constants/payments';

import { cancelFlow_subscriptionClick } from 'services/analytics/trackers/cancelFlow';

import { replaceUnderscoreToDash } from 'helpers/utils';
import { getPriceFromCents } from 'helpers/prices';
import { getUpcomingProduct, getSubscriptionIcon, getSubscriptionName, normalizeDateByLocale } from '../helpers';

import { ISubscriptionCard } from '../types';

const TRIAL_NAME = 'subscription.type.trial';
const SUBSCRIPTION_NAME = 'subscription.type.subscription';

const useSubscriptionCard = ({ onCancelSubscription, subscription }: ISubscriptionCard) => {
    const {
        fee,
        state,
        reminder,
        discounts,
        external_id,
        product: { price: fullPrice, currency, trial_price: trialPrice },
        is_trial: isTrial,
        expired_date: expiredDate,
        cancelled_at: cancelledAt,
        product_code: productCode,
        period: subscriptionPeriod,
        trial_period: subscriptionTrialPeriod,
        internal_cancel_at: internalCancelAt,
    } = subscription;
    const { t } = useTranslation();

    const isSubscriptionPaused = Boolean(state === SUBSCRIPTION_PAUSED && !discounts.free_month);
    const isFreeMonthActive = Boolean(state === SUBSCRIPTION_PAUSED && discounts.free_month);
    const isCancelledSubscription =
        state === SUBSCRIPTION_CANCELLED || Boolean(cancelledAt) || Boolean(internalCancelAt);
    const isSubscriptionActive = state === SUBSCRIPTION_ACTIVE;
    const isSubscriptionRedemption = state === SUBSCRIPTION_REDEMPTION;
    const isExpiredSubscription =
        (isCancelledSubscription && !isSubscriptionActive) || state === SUBSCRIPTION_CHARGEBACK;
    const isExpiresSubscription = isCancelledSubscription && isSubscriptionActive;
    const isPendingSubscription = state === SUBSCRIPTION_PENDING;
    const isMainSubscription = productCode === PRODUCT_CODES.APP_FULL_ACCESS;

    const country = useSelector(selectCountry);

    const expirationDate = normalizeDateByLocale(expiredDate, country);
    const cancelledAtDate = normalizeDateByLocale(cancelledAt, country);

    const tabIcon = getSubscriptionIcon(productCode);
    const heading = replaceUnderscoreToDash(getSubscriptionName(productCode));
    const price = isTrial ? trialPrice : fullPrice;

    const feeData =
        fee?.fee_enabled || (fee?.fee_expired_at && isCancelledSubscription)
            ? {
                  fee_amount: `${getPriceFromCents(fee.fee_amount, currency)} ${currency}`,
                  fee_total_price: `${getPriceFromCents(fee.fee_total_price, currency)} ${currency}`,
                  fee_expired_at: normalizeDateByLocale(fee.fee_expired_at, country),
              }
            : null;

    const handleCancelSubscription = () => {
        cancelFlow_subscriptionClick({
            status: state,
            action: 'cancel',
            reminder: !!reminder,
            free_month: isFreeMonthActive,
            subscription_id: external_id,
        });

        onCancelSubscription(subscription);
    };

    const getExpirationDate = () => {
        if (isExpiresSubscription) {
            return replaceUnderscoreToDash(expirationDate);
        }

        return replaceUnderscoreToDash(cancelledAt ? cancelledAtDate : expirationDate);
    };

    return {
        feeData,
        price: `${price} ${currency}`,
        state,
        tabIcon,
        heading,
        upcomingProduct: getUpcomingProduct({ subscription, country }),
        isSubscriptionRedemption,
        isExpiresSubscription,
        isExpiredSubscription,
        isPendingSubscription,
        isSubscriptionPaused,
        isFreeMonthActive,
        cancelledAtDate,
        isMainSubscription,
        handleCancelSubscription,
        subscriptionType: t(isTrial ? TRIAL_NAME : SUBSCRIPTION_NAME),
        expirationDate: getExpirationDate(),
        subscriptionPeriod: isTrial ? subscriptionTrialPeriod : subscriptionPeriod,
    };
};

export default useSubscriptionCard;
