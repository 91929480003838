/* eslint-disable react/no-children-prop */
/* eslint-desable jsx-a11y/anchor-is-valid */
import { Trans, useTranslation } from 'react-i18next';
import React, { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';

import links from 'appConfig/links';

import { getLanguage } from 'api/utils/languageManagment';

import { selectCountry } from 'store/user/selectors';

import {
    OfferTypes,
    cancelFlow_cancelOfferClick,
    cancelFlow_cancelOfferScreenLoad,
} from 'services/analytics/trackers/cancelFlow';

import { useCancelSubscription, useModals, useSelector } from 'hooks';

import { normalizeDateByLocale } from 'pages/AccountSettings/components/SubscriptionsTab/components/SubscriptionCard/helpers';
import { getPriceFromCents } from 'helpers/prices';

import OutlinedButton from 'components/OutlinedButton/OutlinedButton';
import ModalHeader from 'components/ModalHeader/ModalHeader';
import Modal from 'components/Modal/Modal';
import { Box, Button, Image, Input, Text } from 'components/library';

import * as S from './styled';

import feeIcon from 'assets/img/icons/fee.png';

import themeConfig from 'themeConfig';

const TermsLink: FC<PropsWithChildren> = ({ children }) => {
    const language = getLanguage();

    return (
        <a href={`${links.termsOfUse}?language=${language}`} target="_blank" rel="noreferrer">
            <Text inline medium type="large-text" className="text-underline" children={children} />
        </a>
    );
};

const CancelationFeeModalContainer = () => {
    const { t } = useTranslation();
    const { closeModal } = useModals();
    const country = useSelector(selectCountry);
    const [inputValue, setInputValue] = useState<string>('');
    const [cancelConfirmed, setConfirmed] = useState(false);
    const isConfirmed = inputValue.trim().toLowerCase() === 'confirm';

    const { cancellationCandidate, onCancelSubscription } = useCancelSubscription();
    const expirationDate = normalizeDateByLocale(cancellationCandidate!.expired_date, country);

    const feeData = useMemo(() => {
        const {
            fee,
            product: { currency },
        } = cancellationCandidate!;

        return {
            ammount: `${getPriceFromCents(fee.fee_amount, currency)} ${currency}`,
        };
    }, [cancellationCandidate]);

    useEffect(() => {
        if (cancellationCandidate) {
            cancelFlow_cancelOfferScreenLoad({
                offer_type: OfferTypes.Fee_offer,
                subscription_id: cancellationCandidate.external_id,
                fee_ammount: cancellationCandidate.fee.fee_price,
            });
        }
    }, [cancellationCandidate]);

    const handleClose = () => {
        closeModal();

        if (cancellationCandidate) {
            cancelFlow_cancelOfferClick({
                action: 'close',
                subscription_id: cancellationCandidate.external_id,
                offer_type: OfferTypes.Fee_offer,
                fee_amount: cancellationCandidate?.fee.fee_price,
            });
        }
    };

    const handleGoBack = () => {
        setInputValue('');
        setConfirmed(false);
    };

    const handle1stCancel = () => {
        setConfirmed(true);

        if (cancellationCandidate) {
            cancelFlow_cancelOfferClick({
                action: 'claim_offer',
                subscription_id: cancellationCandidate.external_id,
                offer_type: OfferTypes.Fee_offer,
                fee_amount: cancellationCandidate?.fee.fee_price,
            });
        }
    };

    const handle2ndCancel = () => {
        onCancelSubscription();

        if (cancellationCandidate) {
            cancelFlow_cancelOfferClick({
                action: 'cancel',
                subscription_id: cancellationCandidate.external_id,
                offer_type: OfferTypes.Fee_offer,
                fee_amount: cancellationCandidate?.fee.fee_price,
            });
        }
    };

    return (
        <Modal isOpen fullscreen={!cancelConfirmed} withCloseButton={cancelConfirmed} onClose={handleClose}>
            {cancelConfirmed ? (
                <Box paddingTop={60} paddingBottom={20} paddingX={16}>
                    <Text type="h5" bold center mb={20} text={t('subscription.cancellation.feeOffer.title2')} />
                    <Text mb={10} center type="medium-text" text={t('subscription.cancellation.feeOffer.subtitle2')} />
                    <Input
                        type="text"
                        isFocus={false}
                        value={inputValue}
                        name="confirmation"
                        theme={themeConfig.theme}
                        placeholder={t('subscription.cancellation.feeOffer.hint')}
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                    <Button text={t('subscription.text.cancel')} onClick={handle2ndCancel} disabled={!isConfirmed} />
                    <Box paddingTop={16}>
                        <OutlinedButton text={t('subscription.text.goBack')} onClick={handleGoBack} />
                    </Box>
                </Box>
            ) : (
                <>
                    <ModalHeader onClose={handleClose} />
                    <S.Container>
                        <Image center maxWidth={200} src={feeIcon} alt="Fee" mb={12} />
                        <Text type="h4" medium center mb={20} text={t('subscription.cancellation.feeOffer.title')} />
                        <Text mb={10} center type="large-text">
                            <Trans
                                i18nKey="subscription.cancellation.feeOffer.subtitle"
                                components={[
                                    <TermsLink key="0" />,
                                    <Text key="1" inline medium type="large-text" text={`${feeData.ammount}`} />,
                                    <Text key="2" inline medium type="large-text" text={expirationDate} />,
                                ]}
                            />
                        </Text>
                        <Button text={t('subscription.text.cancel')} onClick={handle1stCancel} />
                        <OutlinedButton text={t('subscription.text.keep')} onClick={handleClose} />
                    </S.Container>
                </>
            )}
        </Modal>
    );
};

export default CancelationFeeModalContainer;
