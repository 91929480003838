import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import {
    cancelFlow_cancelResultClick,
    cancelFlow_offerResultClick,
    cancelFlow_offerResultScreenLoad,
} from 'services/analytics/trackers/cancelFlow';

import { useModals } from 'hooks';

import Modal from 'components/Modal/Modal';
import { Box, Button, Image, Text } from 'components/library';

import thumbUp from 'assets/img/icons/thumbUp.webp';
import success from 'assets/img/icons/success.png';
import handsUp from 'assets/img/icons/handsUp.webp';

import { SuccessModalContainerProps } from './types';

const icons = { success, thumbUp, handsUp };

const SuccessModalContainer = ({
    offerType,
    title,
    subtitle,
    icon,
    onClose,
    externalId,
}: SuccessModalContainerProps) => {
    const { t } = useTranslation();
    const { closeModal } = useModals();

    useEffect(() => {
        if (offerType) {
            cancelFlow_offerResultScreenLoad({
                result: 'success',
                offer_type: offerType,
                subscription_id: externalId,
            });
        }
    }, [externalId]);

    const handleClose = (action: 'got_it' | 'close') => {
        if (offerType) {
            cancelFlow_offerResultClick({
                action: 'close',
                result: 'success',
                offer_type: offerType,
                subscription_id: externalId,
            });
        }

        if (externalId && !offerType) {
            cancelFlow_cancelResultClick({
                action,
                result: 'success',
                subscription_id: externalId,
            });
        }

        onClose ? onClose() : closeModal();
    };

    return (
        <Modal isOpen withCloseButton onClose={() => handleClose('close')}>
            <Box paddingTop={60} paddingBottom={20} paddingX={16}>
                {icon && <Image center maxWidth={50} src={icons[icon]} alt={icon} mb={12} />}
                <Text type="h5" bold center mb={24} text={title} />
                <Text type="medium-text" center text={subtitle} />
                <Box paddingTop={25}>
                    <Button onClick={() => handleClose('got_it')} text={t('basics.gotIt')} />
                </Box>
            </Box>
        </Modal>
    );
};

export default SuccessModalContainer;
