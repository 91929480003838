import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import {
    OfferTypes,
    cancelFlow_offerResultScreenLoad,
    cancelFlow_cancelResultClick,
} from 'services/analytics/trackers/cancelFlow';

import { useCancelSubscription, useModals } from 'hooks';

import Modal from 'components/Modal/Modal';
import { Box, Button, Image, Text } from 'components/library';

import mailImage from './img/mail.svg';

const ReminderHasBeenSetModalContainer = () => {
    const { t } = useTranslation();
    const { closeModal } = useModals();
    const { cancellationCandidate } = useCancelSubscription();

    useEffect(() => {
        cancelFlow_offerResultScreenLoad({
            result: 'success',
            offer_type: OfferTypes.Reminder_offer,
            subscription_id: cancellationCandidate?.external_id,
        });
    }, []);

    const handleClose = () => {
        if (cancellationCandidate) {
            cancelFlow_cancelResultClick({
                result: 'success',
                action: 'continue',
                subscription_id: cancellationCandidate.external_id,
            });
        }

        closeModal();
    };

    return (
        <Modal isOpen withCloseButton onClose={handleClose}>
            <Box paddingTop={24} paddingBottom={24} paddingX={16}>
                <Image center maxWidth={72} src={mailImage} alt="sandClock" mb={20} />
                <Text type="h5" bold center mb={24} text={t('subscription.reminderHasBeenSetModal.title')} />
                <Text type="medium-text" center mb={24} text={t('subscription.reminderHasBeenSetModal.subTitle')} />
                <Button
                    onClick={handleClose}
                    backgroundColor="primary-default"
                    text="basics.continue"
                    dataLocator="obContinue"
                />
            </Box>
        </Modal>
    );
};

export default ReminderHasBeenSetModalContainer;
